<template>
  <router-view/>
</template>

<script>
//import ScreenLoader from "@/components/shared/ScreenLoader.vue";
import { getAPI } from '@/axios-api.js'
import { useRouter } from "vue-router";
import  { ref, computed } from "vue"
import { useStore } from "vuex";
//import { mapGetters } from "vuex";
export default {
  setup() {
    const router = useRouter()
    const store = useStore()
    console.log(router.currentRoute.value)
    function activeColor(color1,color2) {
      const primaryColor = color1;
      console.log("color:",color1);
      if(primaryColor){
        const primaryRgb = hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = color2;
      if(secondaryColor){
        const secondaryRgb = hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    }
    function hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    }
    //eslint-disable-next-line
    async function setThemeColor () {
      console.log("on set Theme color")
      await getAPI.get(`organisations/${localStorage.organisation}/`)
        .then(response => {
          console.log(response)
          if (response.status == 200) {
            let organisationData = response.data
            
            activeColor(organisationData.primary_color, organisationData.secondary_color);
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const isLoading = ref(false)
    /*if (![ '/', '/login', '/sign-up', '/sign-up-with-code', 'AccountActivationview', 'Accept-invite', 'AccountActivationResent', 'PasswordForget', 'ResetPassword', 'Log', 'Log', 'Log', 'Mentions', 'Conditions', 'CheckCertificate', '404', 'Sign-in', 'Sign-up', 'forgot-password', 'resetPassword', 'resendActivationEmail', 'accountActivation', 'newregisterwithCode'].includes(router.currentRoute.value.path)) {
      console.log("yeah")
      //setThemeColor()
    }*/
    //const isToken  = localStorage.getItem("token")
    /*if (isToken && isToken != "") {
      setThemeColor()
    }*/
    

    return {
      isLoading,
      isPageLoad : computed(()=> store.state.isPageLoad)
    }
  },
  components: {
    //ScreenLoader
  },
  computed : {
    //...mapGetters(["getPageLoad"])
  },
  methods : {
    
    
  },
  async created () {
    //await this.setThemeColor()
    //this.isLoading = true
    //this.$store.commit('loadPage', true)
  },
  onBeforeMount() {
    console.log("oooooooooooooooooooooooooooooooooooonnnnnnnnnnnnnnnnnnnnnnnnnnhhhhhhhhhhhheeeeeeeeeeerrrrrrrrreeeeeeeeeeeeee")
  },
  async beforeRouteEnter () {
    //console.log("onRouteUpdate")
    //await this.setThemeColor()
  },
  mounted() {
    
  }
}
</script>

<style>
  /* fadeX */
  .fade-enter-active, .fade-leave-active{
    transition: opacity 1s, transform 1s;
  }
  .fade-enter, .fade-leave-active{
    opacity: 0;
    transform: translateX(20px);
  }
  .fade-move{
    transition: transform 1s;
  }

  /* fadeY */
  /*.fadeY-enter-active, .fadeY-leave-active{
    transition: opacity 1s, transform 1s;
  }
  .fadeY-enter, .fadeY-leave-active{
    opacity: 0;
    transform: translateY(20px);
  }
  .fadeY-move{
    transition: transform 1s;
  }*/

  .fadeY-enter-active {
    transition: all 0.3s ease-out;
  }
  .fadeY-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fadeY-enter-from,
  .fadeY-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }
  /* *** */

  .bounce-enter-active{
    animation: bounce-in .5s;
  }

  .bounce-leave-active{
    animation: bounce-out .5s;
  }

  @keyframes bounce-in{
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes bounce-out{
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(0);
    }
  }

  /* preloader */
  .preloader_center{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .preloader_center img{
    width: 7em;
    z-index: 1111;
  }
  .g-tooltip {
    display: none;
    position: fixed !important;
    background-color: #74d3d8;
    padding: 10px;
    width: 200px;
    color: black;
    z-index: 10000;
    border-radius: 4px;
  }
  .g-tooltip::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #74d3d8;
  }
  html{
    zoom: 1;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0;
  }
</style>

<template>
    <div :id="uid" :style="(filterMode == 'ALL') ? 'display: block' : ((started == true) ? 'display: block' : 'display: none')" :class="(viewMode == 'GROUP') ? cssClass : 'cfa-module col-12 mb-4'">
      <div :id="uid+'-content'" :class="(viewMode == 'GROUP') ? 'p-3 bg-white cfa-course-module-inner cfa-module-shadow' : 'p-3 bg-white cfa-course-module-inner d-flex flex-row cfa-module-shadow'">
        <div>
          <div class="cfa-module-image position-relative">
            <img :src="preview" alt="cfa module course image"/>
            <button style="text-transform: uppercase;" class="gType btn btn-discret cfa-btn-primary">{{type}}</button>
          </div>
        </div>
        <div :class="(viewMode == 'GROUP') ? '' : 'ms-3 w-100'">
          <div class="py-3">
            <button class="btn btn-discret cfa-btn-primary">
              <img width="10" src="../../../assets/Icons/All_Icon/Vector-7.png" alt="cfa assets"/>
              {{label}}
            </button>
          </div>
          <div class="py-2">
            <h2 style="height: 1.2em; font-size: 1.1em; overflow: hidden;"><strong>{{title}}</strong></h2>
          </div>
          <div class="py-2 pb-3" style="height: 5em; overflow: hidden;" v-html="description"></div>
          <div class="py-2 pt-4">
            <c-f-a-progress v-if="module_stats != null" :percent="(module_stats?.progression == 0) ? 1 : module_stats?.progression" :active="true"></c-f-a-progress>
            <c-f-a-progress v-else :percent="0" :active="false"></c-f-a-progress>
          </div>
          <div class="py-2 pb-0 d-flex align-items-center justify-content-between">
            <div class="">
                <img width="15" src="../../../assets/img/png/timer.png"/>
                {{max__horaire}} <!-- minutes -->
            </div>
            <a v-if="module_stats != null && module_stats.progress == 100" :href="'/modules-details/'+id">
                <button class="btn cfa-btn-gold">
                    Reprendre&nbsp;<i class="fa fa-repeat"></i>
                </button>
            </a>
            <a v-else href="/toward-module-content">
                <button @click.prevent="continueModule" v-if="module_stats != null" class="btn cfa-btn-primary">
                    Continuez&nbsp;<i class="fa fa-arrow-right"></i>
                </button>
                <button @click.prevent="initStat" v-else-if="remain_days != ''" class="btn cfa-btn-primary">
                    {{$t('get_started')}}&nbsp;
                    <i v-if="!waitAPI" class="fa fa-arrow-right"></i>
                    <img v-else src="@/assets/img/gif/load2.gif" alt="loading gif" style="width: 20px;"/>
                </button>
                <button @click.prevent="initStat2" v-else-if="remain_days == ''" class="btn cfa-btn-danger">
                  {{$t('get_started')}}&nbsp;
                    <i v-if="!waitAPI" class="fa fa-arrow-right"></i>
                    <img v-else src="@/assets/img/gif/load2.gif" alt="loading gif" style="width: 20px;"/>
                </button>
            </a>
        </div>
        </div>
      </div>
    </div>
</template>

<script>
import CFAProgress from '@/components/shared/progress/CFAProgress.vue'
import {getAPI} from "@/axios-api";

export default {
  name: 'CourseCard',
  components: {
    CFAProgress
  },
  props: {
    uid: {
      type: String,
      default: 'cfa-course-01'
    },
    preview: {
      type: String,
      default: 'https://picsum.photos/300/201'
    },
    id: {
      type: String,
      default: '6de8547856985412254dj44522'
    },
    label: {
      type: String,
      default: 'Module 1'
    },
    type: {
      type: String,
      default: 'FORENSIC'
    },
    title: {
      type: String,
      default: 'Sécurité du post de travail.'
    },
    description: {
      type: String,
      default: 'lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem'
    },
    started: {
      type: Boolean,
      default: true
    },
    finished: {
      type: Boolean,
      default: false
    },
    max__horaire: {
      type: String,
      default: '22'
    },
    viewMode: {
      type: String,
      default: 'GROUP'
    },
    filterMode: {
      type: String,
      default: 'ALL'
    },
    cssClass: {
      type: String,
      default: 'cfa-module col-12 col-md-4 mb-4'
    },
    percent: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      userId: localStorage.getItem('user'),
      module_stats: null,
      waitAPI: false,
      subscriptionData: [],
      remain_days: "",
      userRole: localStorage.getItem("role")

    }
  },
  methods: {
      retrieveUserSubscription () {
            this.waitingAPIResponse = true
            getAPI.get(`subscriptions/find-subscription`).then(response => {
              if(response.status == 200) {
                this.subscriptionData = response.data.data

                if(this.subscriptionData != null){
                console.log("Infos: ", this.subscriptionData)
                }
                
                this.remain_days = this.subscriptionData.remain_days

                const created_at = new Date(this.subscriptionData.created_at)
                created_at.setDate(created_at.getDate() + 30);
                
                const expirationDate = created_at.toISOString();

                this.expiration_date = expirationDate
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                  this.waitingAPIResponse = false
              }, 2000);
            }).catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false
            })
      },
    continueModule () {
      let a = document.createElement('a')
      a.setAttribute('href', `/modules-details/${this.id}`)
      a.click()
      a.remove()
    },
    initStat () {
      this.waitAPI = true
      getAPI.post(`stat_module/`, {
        module: this.id,
        user: this.userId,
        //progression: 1
      })
      .then(response=>{
        console.log("Module: ", this.id, " User: ", this.userId)
        console.log(response)
        //return
        //return
        //eslint-disable-next-line
        if(response.status == 201) {
            this.getModuleStats();
            this.module_stats = (response.data.success) ? response.data.data : null;
            let a = document.createElement('a')
            a.setAttribute('href', `/modules-details/${this.id}`)
            a.click()
            a.remove()
        }
        this.waitAPI = false
      })
      .catch((error) => {
            console.log(error)
            
            /*this.getModuleStats();
            this.module_stats = (error.response.data.data.success) ? error.response.data.data : null;
            let a = document.createElement('a')
            a.setAttribute('href', `/modules-details/${this.id}`)
            a.click()
            a.remove()*/
            if (error.response.data.message == "Your previous module is not finished") {

              this.$swal({
                 title: 'Modules',
                 text: (error.response.data.message == 'You are not valid subscription') ? "" : ((error.response.data.message == "Your previous module is not finished") ? "Vous devez terminé le module précédent avant d'en démarrer un autre !" : error.response.data.message),
                 type: 'warning',
                 showCancelButton: false,
                 confirmButtonText: `fermer`,
             });
            }

          /*this.$swal('Hello Vue world!!!');
          //let msg = (error.response.data.message == 'You are not valid subscription') ? '<div style="display: flex;justify-content: space-between; align-items: center; position: relative; top: -0.3em; width: 30em;"><span>Vous n\'avez encore souscrire à aucun plan !</span><a href="/facturation"><button style="background-color: #06bbc4; color: #fff; transition: all ease-in .2s; outline: none; border: none; border-radius: 5px; padding: 0.1em 0.5em; padding-top: 0.5em;">SOUSCRIRE</button></a></div>' : error.response.data.message
          this.$emit('error', msg)*/
          // console.log('An error occur while loading module !')
          this.waitAPI = false
      })
    },
    initStat2 () {
      this.waitAPI = true
      getAPI.post(`stat_module/`, {
        module: this.id,
        user: this.userId,
        //progression: 1
      })
      .then(response=>{
        console.log("Module: ", this.id, " User: ", this.userId)
        console.log(response)
        //return
        //eslint-disable-next-line
        if(response.status == 201) {
            this.getModuleStats();
            this.module_stats = (response.data.success) ? response.data.data : null;
            let a = document.createElement('a')
            a.setAttribute('href', `/modules-details/${this.id}`)
            a.click()
            a.remove()
        }
        this.waitAPI = false
      })
      .catch((error) => {
          this.$swal({
              title: 'Oups ! Vous n\'avez encore souscrire à aucun plan.',
              text: (error.response.data.message == 'You are not valid subscription') ? "" : ((error.response.data.message == "Your previous module is not finished") ? "Vous devez terminé le module précédent avant d'en démarrer un autre !" : error.response.data.message),
              type: 'warning',
              showCancelButton: false,
              confirmButtonText: this.userRole == 'manager' ?  `<a href="/facturation/pricing" style="text-decoration: none; font-weight: 400; color: white;">Aller à la facturation</a>` : `<span>fermer</span>`,
          });

          /*this.$swal('Hello Vue world!!!');
          //let msg = (error.response.data.message == 'You are not valid subscription') ? '<div style="display: flex;justify-content: space-between; align-items: center; position: relative; top: -0.3em; width: 30em;"><span>Vous n\'avez encore souscrire à aucun plan !</span><a href="/facturation"><button style="background-color: #06bbc4; color: #fff; transition: all ease-in .2s; outline: none; border: none; border-radius: 5px; padding: 0.1em 0.5em; padding-top: 0.5em;">SOUSCRIRE</button></a></div>' : error.response.data.message
          this.$emit('error', msg)*/
          // console.log('An error occur while loading module !')
          this.waitAPI = false
      })
    },
    getModuleStats() {
        getAPI.get(`stat_module/user/${this.userId}/${this.id}/`).then(response=>{
            console.log(response)
            if(response.status == 200) {
                this.module_stats = (response.data.success) ? response.data.data : null;
            }
        }).catch((err) => {
            console.log(err)
            console.log('An error occur while loading module !')
        })
    }
  },
  watch: {
  },
  mounted() {
    this.getModuleStats();
    this.retrieveUserSubscription();
  }
}
</script>

<style scoped>
.cfa-course-module-inner{
  border-radius: .5em;
}
.cfa-module-image{
  /* width: 500px; */
  border: 2px solid #f0f1f6;
  border-radius: .5em;
  overflow: hidden;
  min-height: 16em;
  background-color: var(--primary-light-color-2);
}
.cfa-module-image img{
  width: 100%;
  min-width: 320px;
  min-height: 260px;
  max-height: 260px;
  transition: all ease-in .3s;
  color: var(--primary-light-color-2);
}
.cfa-module-image img:hover{
  transform: scale(1.2) !important;
}
.gType{
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.cfa-module{
  transition: all ease-in .3s;
}
.cfa-module-shadow{
  /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
</style>

<template>
    <div class="cfa-root">
        <c-f-a-nav :alert="alertString">
            <template v-slot:title>
                <div class="d-flex align-items-center justify-content-between w-100 pt-5 text-start mb-3 ftwru">
                    <div class="d-flex align-items-center justify-content-center">
                        <a href="/facturation">
                            <button class="btn cfa-btn-primary btn-icon" >
                                &nbsp;<i class="fa fa-chevron-left">&nbsp;</i>
                            </button>
                        </a>&nbsp;&nbsp;
                        <h1 class="cfa-section-title">{{ $t('Billing ') }} / {{ $t('Subscription') }}</h1>
                    </div>

                    <!--<button @click="openModal" class="btn cfa-btn-primary" style="height: 2.7em;">
            METTRE À JOUR MA SOUSCRIPTION&nbsp;<i class="fa fa-plus">&nbsp;</i>
          </button>-->
                </div>
                <br>
            </template>
            <template v-slot:content>
                <!-- subscription list -->
                <div v-show="!waitingAPIResponse" class="cfa-page">
                    <!--<div class="">
                        <button class="btn cfa-btn-light-custom">SOUSCRIPTION ACTUELLE</button>
                        <Table min-height="5em" :hide-control="true" :columns="subscriptionsColumns" :data="subscriptions" :columns-action="subscriptionsToListen" @action="getCallToAction" last-cell-width="0"></Table>
                    </div>
                    <br><br>-->
                    <div class="">
                        <!--<button class="btn cfa-btn-light-custom">PLAN DISPONIBLES</button>-->
                        <div style=""><!-- border: 5px solid #e3ecfb; min-height: 5em; width: 100%; padding: 0.8em; -->
                            <div class="row" v-if="plans.length > 0">
                                <div v-for="(plan, id) in plans" :key="id" class="col-lg-4 patch-pf-dot-4">
                                    <!-- {{ plan }} -->
                                    <plan-card v-if="plan.is_visible" :id="plan.id" :yearly_price="plan.price_year"
                                        :yearly_price_id="plan.stripe_id_price_year" :monthly_price="plan.price_month"
                                        :monthly_price_id="plan.stripe_id_price_month" :type="plan.type_plan"
                                        :descrirption="plan.description" :name="plan.name" currency_code="$"
                                        :is_default="id === 1" @alert="getAlert">
                                    </plan-card>
                                </div>
                                <br><br><br><br>



                            </div>
                            <h2 class=" mt-3">Autres</h2>
                            <div class="sc-1pn2crt-0 sc-1ncashl-2 hBHdds cHKOfE mt-4">
                                <div class="sc-1ncashl-6 dqvPHt">
                                    <h3 class="sc-1r1srh6-0 sc-1ncashl-4 johGGf fQRNzA">Enterprise</h3>
                                    <p class="sc-16px0aw-0 sc-1ncashl-5 dufgQY kakozg">
                                        {{ $t('for_organizations') }}
                                    </p>
                                </div>
                                <!-- <div class="sc-1ncashl-6 dqvPHt">
                              <ul class="sc-1ncashl-7 foswWw">
                                  <li class="sc-1ncashl-10 bRktZd" style="margin-bottom: 0.5em; color: #132B4D;">
                                      <span class="sc-1ncashl-8 eTcbRn">
                                         <svg fill="black" id="Tick_inactive" data-name="Tick/inactive" width="10" height="10" viewBox="0 0 10 10">
                                            <path id="tick_icon" d="M3.781,7a1.782,1.782,0,0,1-1.229-.49L.227,4.253A.75.75,0,0,1,1.272,3.177L3.589,5.426a.279.279,0,0,0,.205.074A.3.3,0,0,0,4,5.4L8.7.245A.75.75,0,1,1,9.8,1.255L5.111,6.411A1.789,1.789,0,0,1,3.849,7H3.781" transform="translate(0 1.5)" fill="current"></path>
                                         </svg>
                                      </span>&nbsp;
                                      <p class="sc-1ncashl-9 ftlDdW">Lorem ipsum dolor spectorly from cyberspector for plan </p>
                                  </li>
                                  <li class="sc-1ncashl-10 bRktZd" style="margin-bottom: 0.5em; color: #132B4D;">
                                      <span class="sc-1ncashl-8 eTcbRn">
                                         <svg fill="black" id="Tick_inactive" data-name="Tick/inactive" width="10" height="10" viewBox="0 0 10 10">
                                            <path id="tick_icon" d="M3.781,7a1.782,1.782,0,0,1-1.229-.49L.227,4.253A.75.75,0,0,1,1.272,3.177L3.589,5.426a.279.279,0,0,0,.205.074A.3.3,0,0,0,4,5.4L8.7.245A.75.75,0,1,1,9.8,1.255L5.111,6.411A1.789,1.789,0,0,1,3.849,7H3.781" transform="translate(0 1.5)" fill="current"></path>
                                         </svg>
                                      </span>&nbsp;
                                      <p class="sc-1ncashl-9 ftlDdX">Lorem ipsum dolor spectorly from cyberspector for plan </p>
                                  </li>
                                  <li class="sc-1ncashl-10 bRktZd" style="margin-bottom: 0.5em; color: #132B4D;">
                                      <span class="sc-1ncashl-8 eTcbRn">
                                         <svg fill="black" id="Tick_inactive" data-name="Tick/inactive" width="10" height="10" viewBox="0 0 10 10">
                                            <path id="tick_icon" d="M3.781,7a1.782,1.782,0,0,1-1.229-.49L.227,4.253A.75.75,0,0,1,1.272,3.177L3.589,5.426a.279.279,0,0,0,.205.074A.3.3,0,0,0,4,5.4L8.7.245A.75.75,0,1,1,9.8,1.255L5.111,6.411A1.789,1.789,0,0,1,3.849,7H3.781" transform="translate(0 1.5)" fill="current"></path>
                                         </svg>
                                      </span>&nbsp;
                                      <p class="sc-1ncashl-9 ftlDdX">Lorem ipsum dolor spectorly from cyberspector for plan </p>
                                  </li>
                              </ul>
                          </div> -->
                                <div class="sc-1ncashl-6 dqvPHt">
                                    
                                        <button @click="initContactService()" class="btn w-100 cfa-bg-light-primary "
                                            style="background-color: #cef1f3 !important; color: #fff !important; color: #132B4D !important; padding: 0.7em;">
                                            {{ $t('contact_sales') }}</button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="waitingAPIResponse" class="cfa-page">
                    <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
                        <div class="preloader_center d-flex flex-column">
                            <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                            <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </c-f-a-nav>
        <c-f-a-footer></c-f-a-footer>
        <!-- create -->
        <scalable-modal @callback="addNewPlan" :break="exitAddPlanModal" display-mode="block" label="CRÉER UN PLAN"
            uid="cfa-modal-add-plan" @close="newPlan = false" :open="newPlan" :advancedControl="true"
            validationButtonText="Mettre à jour" exitButtonText="Annuler"
            :style="'width: 85vw; height: auto; margin-top: 5em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
                <Table v-show="!waitingAPIResponse" :columns="plansColumns" :data="plans"
                    :columns-action="plansToListen" @action="getCallToAction1" last-cell-width="0"></Table>
                <div v-show="waitingAPIResponse" class="cfa-page">
                    <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
                        <div class="preloader_center d-flex flex-column">
                            <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                            <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </scalable-modal>

        <!-- contact enterprise-->
        <scalable-modal @callback="contactService" :break="exitContactServiceModal" label="CONTACTER SERVICE COMMERCIALE" uid="cfa-modal-contact-service-create" @close="openContactService=false" :open="openContactService"  :advancedControl="true" validationButtonText="Envoyer" exitButtonText="Annuler" displayMode="flex" :style="'width: 40vw; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
          <template v-slot:content>
              <!-- blo1 -->
              <div v-if="!waitingAPIResponse" class="row w-100">
                  <div class="form-group py-2 px-0">
                      <p class="mb-2"><strong>Nom de l'entreprise</strong></p>
                      <div class="d-flex flex-row">
                          <input class="form-control w-100" type="text" v-model="contactServicePayload.enterprise">
                      </div>
                  </div>
                  <div class="form-group py-2 px-0">
                      <p class="mb-2"><strong>Email</strong></p>
                      <div class="d-flex flex-row">
                          <input class="form-control w-100" type="email" style="text-transform:lowercase" v-model="contactServicePayload.email">
                      </div>
                  </div>
                  <div class="form-group py-2 px-0">
                    <p class="mb-2"><strong>No de telephone</strong></p>
                    <!-- <div class="d-flex flex-row">
                        <input class="form-control w-100" type="text" v-model="contactServicePayload.phone">
                    </div> -->
                    <div class="form-group mt-1">
                        <input-phone @updateNumber="updatePhoneNumber"  />
                    </div>
                  </div>
                  <div class="form-group py-2 px-0">
                      <p class="mb-2"><strong>Description</strong></p>
                      <div class="d-flex flex-row">
                          <textarea class="form-control w-100" type="text" v-model="contactServicePayload.description" style="text-transform:lowercase"> </textarea>
                      </div>
                  </div>
              </div>

              

              
          </template>
        </scalable-modal>
    </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import { getAPI } from "@/axios-api";
import Table from "@/components/shared/table/Table.vue";
import scalableModal from "@/components/shared/modal/ScalableModal.vue";
import planCard from "@/components/shared/course/planCard.vue";
import InputPhone from '@/components/shared/input/InputPhone.vue'

export default {
    name: 'FacturationView',
    components: {
        CFAFooter,
        CFANav,
        Table,
        scalableModal,
        planCard,
        InputPhone
    },
    data() {
        return {
            waitingAPIResponse: false,
            subscriptionData: [],
            subscriptions: [],
            subscriptionsColumns: ['N°', 'PLAN', 'MONTANT', 'DATE DEBUT', 'DATE FIN', 'STATUT'],
            subscriptionsToListen: ['ACTIONS'],
            newPlan: false,
            exitAddPlanModal: null,
            plans: [],
            plansColumns: ['N°', 'Nom', 'Prix', 'Capacité', 'Modules', 'Type', 'Autres'],
            plansToListen: ['Autres'],
            alertString: '',
            dashboardData: null,
            dateEnd: null,
            exitContactServiceModal: null,
            openContactService: false,
            contactServicePayload: {
                enterprise: '',
                phone: '',
                email: '',
                description: ''
            }
        }
    },
    methods: {
        getAlert(payload) {
            console.log(payload)
            //this.alertString = `${payload}|alert-no|10000::`+Date.now()
        },
        retrieveUserSubscription() {
            this.waitingAPIResponse = true
            getAPI.get(`subscriptions/find-subscription`).then(response => {
                if (response.status == 200) {
                    this.subscriptionData = response.data.data

                    this.subscriptions = []

                    let row = this.subscriptionData
                    //console.log("Data: ", row)

                    let statut = (row.is_activated) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action" style="background-color: var(--primary-color)"><i class="fa fa-check"> &nbsp;&nbsp;</i>PLAN ACTIF</button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-lock"></i> PLAN INACTIF</button>`;
                    this.subscriptions.push({ 'PLAN': `<span style="font-weight: 400;">${/*row.plan.name*/"plan_name"}</span>`, 'MONTANT': 'montant' + " XOF", 'DATE DEBUT': this.convertDate(row.created_at, 'date_only'), 'JOURS RESTANTS': row.remain_days, 'STATUT': statut, name: row.id, id: row.id })


                    // subscriptionsColumns: ['PLAN', 'MONTANT', 'MODULES', 'DATE DEBUT', 'DATE FIN', 'STATUT'],
                    //   for(let i=0; i<this.subscriptionData.length; i++) {
                    //     let row = this.subscriptionData[i]

                    //     let modules = '<div class="text-start col-12 d-flex flex-wrap">';
                    //     for (let j = 0; j < row.list_modules.length; j++) {
                    //       modules += `<div style="cursor: pointer; border: 1px solid #ccc; padding: 0.5em; margin: 0.5em; border-radius: 0.25em;">Module ${row.list_modules[j].number}</div>`;
                    //     }
                    //     modules += '</div>'

                    //     let statut = (row.is_activated) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action"><i class="fa fa-check"> &nbsp;&nbsp;</i>PLAN ACTIF</button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-lock"></i> PLAN INACTIF</button>`;
                    //     // classify and add to array
                    //     this.subscriptions.push({'N°': (i+1), 'PLAN': `<span style="font-weight: 400;">${row.plan.name}</span>`, 'MONTANT': row.plan.price + " XOF", 'MODULES': modules, 'DATE DEBUT': this.convertDate(row.start_date, 'date_only'), 'DATE FIN': this.convertDate(row.end_date, 'date_only'), 'STATUT': statut,name: row.id, id: row.id})
                    //   }
                    console.log('=============================')
                    console.log(this.subscriptions)
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                setTimeout(() => {
                    this.waitingAPIResponse = false
                }, 2000);
            }).catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false
            })
        },
        async sendEmail(data) {
            /*const emailData = {
                "email": "jediel@gmail.com",
                "message": "Juste un test",
                "subject": "Detail"
            };*/
            const emailData = data
            await getAPI.post('support/send-email-support', emailData)
                .then(response => {
                    console.log(response)
                    if (response.status === 200) {
                        console.log('Email sent successfully');
                        // Handle success
                        const successMessage = 'Email sent successfully';
                        this.alertString = `${successMessage}|alert-ok|5000::${Date.now()}`;
                    } else {
                        console.error('Failed to send email');
                        const errorMessage = 'Failed to send email';
                        this.alertString = `${errorMessage}|alert-no|5000::${Date.now()}`;
                    }
                })
                .catch(error => {
                    console.error('Error sending email:', error);
                    // Handle error (e.g., show an error message)
                });
        },
        initContactService() {
            this.openContactService = true
            //this.sendEmail()
        },
        getDashboardData() {
            this.waitingAPIResponse = true
            getAPI.get(`other/dashboard/`)
                .then(response => {
                    if (response.status == 200) {
                        this.dashboardData = response.data.data
                        this.dateEnd = this.dashboardData?.deadline
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
        },
        convertDate(dateString, format = 'full') {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            if (format === 'full') {
                return `<b>${formattedDate} à ${formattedTime}</b>`;

            }
            return `<b>${formattedDate}</b>`;
        },
        addNewPlan() {
            alert('oulala')
        },
        openModal() {
            this.newPlan = true
            this.getPlans()
        },
        getPlans() {
            this.waitingAPIResponse = true
            getAPI.get(`plans/`)
                .then(response => {
                    if (response.status == 200) {
                        this.plans = response.data.results
                        console.log(this.plans)
                        /*for(let i=0; i<plans.length; i++) {
                            let row = plans[i]
                            let autres = `<div class="d-flex align-items-center">
                                  <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-update"></i> ACTIVER CE PLAN</button>
                             </div>`

                            let modules = '<div class="text-start col-12 d-flex flex-wrap">';
                            for (let j = 0; j < row.modules.length; j++) {
                                modules += `<div style="cursor: pointer; border: 1px solid #ccc; padding: 0.5em; margin: 0.5em; border-radius: 0.25em;">Module ${row.list_modules[j].number}</div>`;
                            }
                            modules += '</div>'

                            if(row.type_plan == localStorage.type_account) {
                                this.plans.push({'N°': (i+1),'Nom': row.name, 'Prix': this.formatPrice(row.price), 'Capacité': row.duration, 'Type': row.type_plan, 'Modules': modules, 'Autres':autres, name: row.id})
                            }
                        }*/
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
        },
        formatPrice(price) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'XOF',
            });
            return formatter.format(price);
        },
        getCallToAction1(payload) {
            if (payload.action_type == 'delete') {
                this.tempDeleteID = payload.id;

                getAPI.post('subscriptions/', {
                    user: localStorage.user,
                    plan: payload.id,
                })
                    .then(response => {
                        if (response.status == 201) {
                            this.retrieveUserSubscription()
                            this.addNewPlan = false;
                            //let msg = 'Souscription creéée avec succès!'
                            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            let firstError = Object.values(error.response.data)[0][0]
                            console.log(firstError)
                            //this.alertString = `${firstError}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.addNewPlan = false;
                    })
            }
        },
        async contactService () {
            console.log("service contact")
            await this.sendEmail(this.contactServicePayload)
            this.exitContactServiceModal = Date.now()
        },
        updatePhoneNumber(number) {
            this.contactServicePayload.phone = number
        }
    },
    beforeRouteEnter() {
        if (localStorage.getItem("role") !== "manager") {
            window.location.href="/dashboard"
        }
    },
    mounted() {
        if (this.$route.query.reason && this.$route.query.reason == "no_subscription") {
            this.$swal({
                title: 'Aucune souscription',
                text: `Veuillez vous souscrire a une formule`,
                type: 'warning',
                showCancelButton: false,
                confirmButtonText: "Fermer"
            });
        }
        this.getPlans()
        //this.retrieveUserSubscription()
        this.getDashboardData()
    }
}
</script>

<style scoped>
.cHKOfE {
    background: linear-gradient(103.75deg, var(--primary-color) -13.9%, rgb(20, 232, 243) 79.68%);
    color: rgb(3, 3, 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    padding: 15px 20px;
    margin: auto;
    margin-top: 3em;
    color: #fff;
}

.cfa-page {}

.cfa-block {
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);

    /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}

.item {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.25em;
}

.bRktZd {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    list-style-type: none;
}
</style>

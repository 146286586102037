<template>
    <div class="loader-container">
        
        <div class="loader">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <span class="loader-text">{{ text }}</span>
        </div>
    </div>
</template>

<script>
//import { mapGetters } from "vuex";

export default {
    name: 'ScreenLoader',
    props: {
        text: {
        type: String,
        default: 'Chargement'
        }
    },
    computed : {
    //...mapGetters(["getPageLoad"])
    },
}
</script>

<style scoped>
.loader-container {
position: fixed;
inset: 0;
display: grid;
place-items: center;
background: white;
z-index: 9999;
}

.loader {
position: relative;
width: 100px;
height: 100px;
}

.circle {
position: absolute;
width: 100%;
height: 100%;
border-radius: 50%;
border: 4px solid transparent;
border-top-color: var(--primary-color);
animation: spin 1s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
}

.circle:nth-child(1) {
animation-delay: 0s;
}

.circle:nth-child(2) {
width: 80%;
height: 80%;
top: 10%;
left: 10%;
/* border-top-color: #2563eb; */
border-top-color: rgba(var(--primary-color-r), var(--primary-color-g), var(--primary-color-b), 0.7);
animation-delay: 0.1s;
animation-direction: reverse;
}

.circle:nth-child(3) {
width: 60%;
height: 60%;
top: 20%;
left: 20%;
/* border-top-color: #3b82f6; */
border-top-color: rgba(var(--primary-color-r), var(--primary-color-g), var(--primary-color-b), 0.5);
animation-delay: 0.2s;
}

.loader-text {
position: absolute;
width: 100%;
text-align: center;
top: 120%;
font-weight: 500;
font-size: 0.875rem;
color: var(--primary-color);
letter-spacing: 0.2em;
text-transform: uppercase;
animation: pulse 2s ease-in-out infinite;
}

@keyframes spin {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

@keyframes pulse {
0%, 100% {
    opacity: 0.5;
}
50% {
    opacity: 1;
}
}
</style>